<template>
  <div v-if="selectedUsuario">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
    >
      <b-card>
        <usuariosForm
          :validarPassword="false"
          usuarioSubmit="Editar Usuario"
          :usuario="selectedUsuario"
          @processUsuario="editarUsuario"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import { BCard, BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const usuariosForm = () => import('@/layouts/components/Usuarios/UsuariosForm.vue')

export default {
  components: {
    BCard,
    BOverlay,
    usuariosForm,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('usuarios', ['selectedUsuario']),
    ...mapState('auth', ['user']),
    ...mapState('auth', ['token']),
  },
  methods: {
    ...mapActions({
      updateUsuario: 'usuarios/updateUsuarios',
      attempt: 'auth/attempt',
    }),
    editarUsuario(usuario) {
      this.spinner = true
      this.updateUsuario(usuario).then(() => {
        const errorUsuarios = store.state.usuarios
        const errorMessage = errorUsuarios.errorMessage.errors
        if (!errorUsuarios.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Usuario editado con éxito 👍',
              text: `El usuario "${usuario.nombre}" fue editado con éxito!`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          if (this.user.id === usuario.id) {
            this.attempt(this.token)
          }
          this.$router.replace({
            name: 'usuarios',
          })
        } else if (errorMessage.correo) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.correo[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (errorUsuarios.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
